<!-- #Developer by Halil Kılıçarslan -->

<template>
  <v-autocomplete
    v-bind="$attrs"
    v-on="$listeners"
    :items="items"
    v-model="selected"
    :loading="loading"
    item-text="name"
    item-value="id"
    :menu-props="{ offsetY: true, overflowY: true, closeOnContentClick: true }"
    clearable
  >
    <template v-if="$attrs.hasOwnProperty('multiple')" v-slot:selection="{ item, index }">
      <div v-if="index === 0">
        {{ index === 0 ? `${selected.length} ${$t("global.selected", [$t("settings.sidebar.company")])}` : "" }}
      </div>
    </template>
    <template v-else v-slot:selection="{ item, index }">
      {{ item.name }}
    </template>

    <template slot="item" slot-scope="data">
      {{ data.item.name }}
    </template>
  </v-autocomplete>
</template>

<script>
  import { COMPANIES } from "./gql";

  export default {
    inheritAttrs: false,
    name: "CompanyPicker",
    data: () => ({
      loading: false,
      selected: null,
      items: []
    }),
    props: {
      value: [String, Object, Array]
    },
    watch: {
      value: {
        handler(v) {
          this.selected = v;
        },
        immediate: true
      }
    },
    methods: {
      async fetchItems() {
        this.loading = true;
        await this.$apollo
          .query({
            query: COMPANIES,
            fetchPolicy: "no-cache",
            errorPolicy: "all"
          })
          .then(({ data, errors }) => {
            if (!data.error && !errors) this.items = data.companies;
          })
          .catch(e => {
            this.$helpers.showNotification(e.message);
          })
          .then(() => {
            this.loading = false;
          });
      }
    },

    created() {
      this.fetchItems();
    }
  };
</script>

<style scoped></style>
